var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm._m(0);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "tableauPlaceholder",
    staticStyle: {
      width: "1366px",
      height: "795px"
    }
  }, [_c("object", {
    staticClass: "tableauViz",
    staticStyle: {
      display: "none"
    },
    attrs: {
      width: "1366",
      height: "795"
    }
  }, [_c("param", {
    attrs: {
      name: "host_url",
      value: "http://52.81.23.78"
    }
  }), _vm._v(" "), _c("param", {
    attrs: {
      name: "embed_code_version",
      value: "3"
    }
  }), _vm._v(" "), _c("param", {
    attrs: {
      name: "site_root",
      value: ""
    }
  }), _vm._v(" "), _c("param", {
    attrs: {
      name: "name",
      value: "Book1&#47;RTMRTPDashboard"
    }
  }), _vm._v(" "), _c("param", {
    attrs: {
      name: "tabs",
      value: "no"
    }
  }), _vm._v(" "), _c("param", {
    attrs: {
      name: "toolbar",
      value: "yes"
    }
  }), _vm._v(" "), _c("param", {
    attrs: {
      name: "showAppBanner",
      value: "false"
    }
  })])]);
}];
render._withStripped = true;
export { render, staticRenderFns };